import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Typography from '../../../primitives/typography';
import StyledLink from '../../../primitives/styled-link';
import { StyledButtonAsLink } from '../../../primitives/styled-button';
import { getLink } from '../../../primitives/link';
import RowSet from '../../../primitives/grid/row-set';
import Divider from '../../../primitives/divider';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import { colors } from '../../../primitives/colors';
import Background from '../../../primitives/backgrounds';

export type ContactCardSetProps = Queries.ContactCardSetPropsFragment;

const ContactCardSet = ({ title, cards }: ContactCardSetProps): JSX.Element => (
    <Background
        css={{
            zIndex: 1,
            position: 'relative',
            backgroundColor: colors.shadesWhite,
            [breakpoints.mb]: {
                marginTop: '65px',
                paddingBottom: '45px',
            },
        }}
        breakpoints={{
            dt: {
                clip: 'bottom',
                gradient: 'greyAlphaRotated',
            },
            mb: {
                clip: 'bottom',
                gradient: 'greyAlphaRotated',
            },
        }}
    >
        <Container css={{
            margin: '60px auto',
            [breakpoints.tb]: {
                margin: '50px auto',
            },
            [breakpoints.mb]: {
                margin: '32px auto',
            },
        }}
        >
            <Typography
                fontSize={{
                    dt: 4053,
                    tb: 3234,
                    mb: 2429,
                }}
                fontWeight="light"
                css={{
                    margin: '60px auto',
                    [breakpoints.tb]: {
                        margin: '50px auto',
                    },
                    [breakpoints.mb]: {
                        margin: '32px auto',
                    },
                }}
            >
                {title}
            </Typography>
            <Row css={{
                rowGap: '32px',
            }}
            >
                {cards && cards.map(card => (
                    <Col
                        breakpoints={{
                            dt: { span: 6 },
                        }}
                        css={{
                            backgroundColor: colors.shadesWhite,
                            border: `1px solid ${colors.grey100}`,
                            borderRadius: borderRadius.medium,
                            padding: '32px',
                        }}
                    >
                        <RowSet breakpoints={{
                            dt: { between: 18 },
                            mb: { between: 12 },
                        }}
                        >
                            <Typography fontSize={{
                                dt: 2429,
                                mb: 1822,
                            }}
                            >
                                {card.regionName}
                            </Typography>
                            {card.groups && card.groups.map((group, idx) => (
                                <>
                                    <RowSet breakpoints={{
                                        dt: { between: 8 },
                                    }}
                                    >
                                        {group?.title && (
                                            <Typography
                                                fontSize={{
                                                    dt: 1627,
                                                }}
                                                fontWeight="medium"
                                            >
                                                {group.title}
                                            </Typography>
                                        )}
                                        {group?.phone && (
                                            <Typography fontSize={{
                                                dt: 1627,
                                            }}
                                            >
                                                Phone
                                                {' '}
                                                <StyledLink to={`tel:${group.phone}`}>
                                                    {group.phone}
                                                </StyledLink>
                                            </Typography>
                                        )}
                                        {group?.email && (
                                            <Typography fontSize={{
                                                dt: 1627,
                                            }}
                                            >
                                                Email
                                                {' '}
                                                <StyledLink to={`tel:${group.email}`}>
                                                    {group.email}
                                                </StyledLink>
                                            </Typography>
                                        )}
                                        {group?.button && group?.button[0] && (
                                            <StyledButtonAsLink variant="secondary" to={getLink(group.button[0]?.link)}>
                                                {group.button[0]?.title}
                                            </StyledButtonAsLink>
                                        )}

                                    </RowSet>
                                    {idx !== card.groups.length - 1 && (
                                        <Divider />
                                    )}
                                </>
                            ))}
                        </RowSet>
                    </Col>
                ))}
            </Row>
        </Container>
    </Background>
);

export const query = graphql`
    fragment ContactCardSetProps on DatoCmsContactCardSet {
        title
        cards {
            regionName
            groups {
                title
                phone
                email
                button {
                    ...LinkWithTitleProps
                }
            }
        }
    }
`;

export default ContactCardSet;
